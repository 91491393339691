.Awards {
  margin-left: 4px;
  display: flex;

  &__award {
    display: inline-flex;
    align-items: center;
    padding: 0 2px;

    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
