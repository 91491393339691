.Home {
  display: flex;
  justify-content: center;
  padding: 16px 0;

  &__posts {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
