.comment {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 4px 0;
  text-align: left;
  border-left: 2px solid #9b9b9bce;

  &__meta {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: rgb(135, 138, 140);
    margin: 8px 0 2px;
    flex-wrap: wrap;

    &__name {
      color: black;
      padding-right: 4px;
    }

    &__space {
      padding: 0 4px;
      &:before {
        content: " \B7 ";
      }
    }

    & > p {
      margin: 0;
    }
  }

  &__body {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0 0 8px;
    word-break: break-word;
  }
}
