.Post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0;

  &__header {
    width: 90%;
    height: 40px;
    padding: 0 20px;

    & > a {
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  & .postCard {
    margin-bottom: 32px;
    cursor: default;
  }

  &__comments {
    border: #8a8a8a 1px solid;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 60%;
    max-width: 700px;
    min-width: 320px;
    overflow: hidden;
    padding: 0 8px;
    background-color: white;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
}
